import React, { useState } from "react"
import { useSpring, animated } from "@react-spring/web"

import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout, { athenaTheme } from "../../../components/layout"
import Hero from "./endlesss/hero"
import styles from "./endlesss.module.scss"

import Info from "./endlesss/info"

import CustomSwiper from "./endlesss/swiper"

import speaker from "../../../images/athena-04-resilience/endlesss/speaker.png"

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic

  const [toggleInfo, setInfoToggle] = useState(false)
  const openInfo = () => {
    setInfoToggle(!toggleInfo)
  }

  const [closed, setOpen] = useState(false)

  const propsIn = useSpring({
    config: { duration: 500 },
    from: { opacity: "0", visibility: "hidden" },
    to: {
      opacity: closed ? "1" : "0",
      visibility: closed ? "visible" : "hidden",
    },
  })

  const [slideOpen, setSlideOpen] = useState("")

  let theme = {
    ...athenaTheme,
    // transition: '0.6s',
    background: slideOpen ? styles.background : styles.slidebkg,
    foreground: styles.foreground,
  }
  return (
    <Layout seo={seo} mode="athena" theme={theme} isEndlesss={true}>
      <Helmet>
        <body className={`athena ${styles.endlesssPage}`} />
      </Helmet>

      {!closed && <Hero closeHero={setOpen} />}

      {closed && (
        <animated.div style={propsIn} className={styles.swiperContainer}>
          <CustomSwiper
            slideOpen={setSlideOpen}
            openInfo={openInfo}
            toggleInfo={toggleInfo}
          />

          <div className={styles.turnYourScreen}>
            <div className={styles.turnYourScreenInner}>
              <h2 className={styles.h2}>
                R<em>o</em>tate yo<b>u</b>r<br />
                devi<b>c</b>e
              </h2>
              <h3 className={styles.h3}>
                This Experience is best in landscape
              </h3>
              <span
                style={{ position: "relative", marginTop: "8rem" }}
                className={styles.soundOn}
              >
                <img src={speaker} alt="speaker" />
                <p>sound on for Best Experience</p>
              </span>
            </div>
          </div>
        </animated.div>
      )}

      <div
        className={`${styles.infoPanel}`}
        style={{
          transition: "width .6s ease-in-out",
          width: toggleInfo ? "100%" : "0%",
          opacity: toggleInfo ? "1" : "0",
          right: toggleInfo ? "0" : "-100%",
          height: toggleInfo ? "100%" : "0px",
        }}
      >
        {toggleInfo && <Info openInfo={openInfo} />}
      </div>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      issues: categories(group: "athenaIssues", orderBy: "dateCreated asc") {
        id
      }
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
`

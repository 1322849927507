import React, { useEffect, useState } from "react"

import styles from "../endlesss.module.scss"

import letsPlayButton from "../../../../images/athena-04-resilience/endlesss/letsplay.png"
import play from "../../../../images/athena-04-resilience/endlesss/heroplay.png"
import TiltPhaseSix from "./imgTilt"

const Hero = props => {
  const [open, setOpen] = useState(true)
  const { closeHero } = props

  const handleClick = () => {
    closeHero(true)
    setOpen(false)
  }

  const [heroFade, setHeroFade] = useState(true)
  useEffect(() => {
    setHeroFade(0)
    let timeoutId = setTimeout(() => {
      setHeroFade(1)
      timeoutId = setTimeout(() => {
        setHeroFade(2)
        timeoutId = setTimeout(() => {
          setHeroFade(3)
          timeoutId = setTimeout(() => {
            setHeroFade(4)
          }, 1100)
        }, 1000)
      }, 2000)
    }, 0)
    return () => clearTimeout(timeoutId)
  }, [])

  const group = index => ({
    style: {
      transition: "all 1.3s ease-in-out",
      opacity: heroFade >= index ? 1 : 0,
    },
  })
  return (
    <>
      <div className={styles.heroContainer}>
        <div className={styles.hero} {...group(1)}>
          <div className={styles.heroTop}>
            <h1>
              <span>The</span>
              <span>
                j<em>o</em>yful
              </span>
              <span>
                <em>r</em>esilien<b>c</b>e
              </span>
            </h1>
            <h1>
              <span>
                pr<em>o</em>je<b>c</b>t:
              </span>
            </h1>
          </div>
          <div className={styles.heroBottom}>
            <h1>
              <span>
                A<b>n</b>
              </span>
              <span>exploration</span>
              <span>of</span>
            </h1>
            <h1>
              <span>
                <b>c</b>ollectiv<b>e</b>
              </span>
              <span>
                creativi<b>t</b>y
              </span>
            </h1>
            <h1>
              <span>
                thro<b>u</b>gh s<em>o</em>und.
              </span>
            </h1>
          </div>
          <div className={styles.heroMobile}>
            <h1>
              <span>
                A<b>n</b>
              </span>
              <span>exploration</span>
            </h1>
            <h1>
              <span>of</span>
              <span>
                <b>c</b>ollectiv<b>e</b>
              </span>
              <span>
                creativi<b>t</b>y
              </span>
            </h1>
            <h1>
              <span>
                thro<b>u</b>gh
              </span>
              <span>
                s<em>o</em>und.
              </span>
            </h1>
          </div>
          <div className={styles.heroPlayContainer} {...group(2)}>
            <div
              className={styles.heroPlayContainerInner}
              {...group(3)}
              onClick={handleClick}
            >
              <img
                className={styles.playButtonPlay}
                {...group(4)}
                src={play}
                alt=""
              />
              <div className={styles.playButton} {...group(4)}>
                <TiltPhaseSix
                  style={{
                    background: `url(${letsPlayButton}) no-repeat center`,
                    backgroundSize: "cover",
                    borderRadius: "50%",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
